// Bu dosya, SSS sayfasını düzenleyebilmeniz için oluşturuldu.
// Markdown ve basit HTML destekler. Lütfen textinizi `` işaretleri
// arasına yazın ve satırların başında boşluk bırakmayın.
// (Not: SSS sayfasını kullanabilmek için .env dosyasında REACT_APP_SSS_PAGE
// değerini true yapmanız gerekiyor.)
// Örnek
// module.exports = String.raw`
// # DENEME
// `

export default String.raw`

`