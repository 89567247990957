module.exports = [
    {
        title: "Çevirmen",
        subtitle: "B2 üstü İngilizce, Japonca dil bilgisi olan kişiler başvurabilir",
        image: "/cevirmen.gif",
        link: "https://discord.gg/seirei",
        button_text: "Başvuru için tıklayınız",
        is_active: 1
    },
    {
        title: "Redaktör",
        subtitle: "Dil bilgisi, iyi bir türkçeye sahip olanlar başvurabilirler (Türkçem çok iyidir deyip, ''-de, -da'' veya en basitinden noktalama işaretlerinde hata yapan adaylar, yetkililerimizce hoş karşılanmazlar.)",
        image: "/redaktor.gif",
        link: "https://discord.gg/seirei",
        button_text: "Başvuru için tıklayınız",
        is_active: 1
    },
    {
        title: "Encoder",
        subtitle: "İşlemci/Ekran kartı iyi olanlar başvurabilir (X'SİZ GT EKRAN KARTLARI KONUYA KAPILDIR.)",
        image: "/encoder.gif",
        link: "https://discord.gg/seirei",
        button_text: "Başvuru için tıklayınız",
        is_active: 1
    },
    {
        title: "Uploader",
        subtitle: "Eğer iyi bir upload hızına sahipseniz veya bir sanal sunucunuz varsa aramıza hoşgeldiniz.",
        image: "/uploader.gif",
        link: "https://discord.gg/seirei",
        button_text: "Başvuru için tıklayınız",
        is_active: 1
    },
    {
        title: "Tasarım + Editör",
        subtitle: "Ph, ae, konusunda uzman olanlar başvurabilir",
        image: "/tasarimci.gif",
        link: "https://discord.gg/seirei",
        button_text: "Başvuru için tıklayınız",
        is_active: 1
    },
    {
        title: "Discord Yetkili alım",
        subtitle: "Discord ses, metin, partner yetkilisi alımlarımız açıktır",
        image: "/yetkilialim.png",
        link: "https://discord.gg/seirei",
        button_text: "Başvuru için tıklayınız",
        is_active: 1
    },
    {
        title: "Topluluk için sunucu alımı",
        subtitle: "Sekai Seifu topluluğu sunucu alımı için başvurabilirsiniz",
        image: "/basvuru.png",
        link: "https://discord.gg/qqK9YjmrKd",
        button_text: "Başvuru için tıklayınız",
        is_active: 1
    },
];